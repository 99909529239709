export enum SocialLinks {
  Instagram = 'https://www.instagram.com/thenovahealth?igsh=bDhuZTN4aDN6Z2E5',
  Facebook = 'https://www.facebook.com/profile.php?id=61554198396885',
  Twitter = '#',
  Reddit = '#',
}
export enum StoresLinks {
  AppleStore = 'https://apps.apple.com/us/app/raising-dog-training-tricks/id6466413368',
  GooglePlay = 'https://play.google.com/store/apps/details?id=com.raisingdog.app',
}
