import React from 'react';

import AppleStoreLogo from 'assets/icons/appStore_badge.svg';
import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AppleStoreBadge = styled(AppleStoreLogo)`
  height: 40px;
`;
const AppleStoreLinkBtn = ({ ...props }) => (
  <StyledLink href={StoresLinks.AppleStore} target="_blank" {...props}>
    <AppleStoreBadge />
  </StyledLink>
);

export default AppleStoreLinkBtn;
